<template>
  <div>
    <!-- <div class="popup" :class="{ popupactive2: campaignPop }">
      <button class="close-vid-btn" type="button" @click="campaignPop = false">
        <img src="../assets/close.svg" alt="close" />
      </button>
      <a href="https://causematch.com/lamdeini23" target="_blank">
        <img
          src="../assets/CAMPAIGN.jpg"
          style="max-height: 400px; object-fit: contain"
          alt="CAMPAIGN"
        />
      </a>
    </div> -->
    <div class="popup-bg" @click="popup = null" v-if="popup">
      <iframe
        @click.stop
        :src="$embedder(popup.video) + '&autoplay=1'"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <topnav></topnav>
    <a href="https://wa.me/972723926886" target="_blank" class="whatsapp">
      <img src="../assets/whatsapp_logo.png" alt="" />
      Whatsapp us today to receive an inspirational weekly tip
    </a>
    <div class="popup" :class="{ popupactive: popupactive }">
      <button class="close-vid-btn" type="button" @click="popupactive = false">
        <img src="../assets/close.svg" alt="close" />
      </button>
      <!-- <iframe
        class="video"
        src="https://drive.google.com/file/d/1FXLxztHTqd8l-V-um_-DXtfHpwvP3Aud/preview"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen=""
      ></iframe> -->
      <video class="video" autoplay muted controls>
        <source
          src="https://sfo2.digitaloceanspaces.com/bagel/Lamdeini/Lamdeini-smaller.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="hero">
      <div class="hero-top-blue"></div>
      <div class="hero-body">
        <div class="hero-main-title-wrap">
          <h1 class="hero-title">
            EVERY · SINGLE · CHILD
            <br />
            כולנו לומדי תורתך
          </h1>
          <p class="txt20">
            LAMDEINI is an initiative based on authentic proven methods to
            ensure every child is equipped to achieve their chelek, their
            portion, in Torah.
          </p>
          <button class="main-btn" type="button" @click="popupactive = true">
            <img src="../assets/play.svg" alt="play video" />Play Video
          </button>
        </div>
      </div>
      <div class="top-tri-bg"></div>
    </div>
    <div id="approach" class="sec">
      <div class="center">
        <h2 class="txt60">Our Approach</h2>
        <div class="small-title-line center"></div>
      </div>
      <div class="approach-wrap">
        <div class="approach-box">
          <img src="../assets/icon1.svg" class="approach-icon" />
          <p class="txt24">Training</p>
          <p class="txt16">
            Tutors are carefully vetted and then trained to use our unique
            methods. Progress is monitored.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon2.svg" class="approach-icon" />
          <p class="txt24">Assessment</p>
          <p class="txt16">
            We work in the schools in conjunction with parents and rebbeim to
            identify students at risk, and thoroughly evaluate each individual
            situation.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon3.svg" class="approach-icon" />
          <p class="txt24">Remediation</p>
          <p class="txt16">
            Our methods are unique and proven to be effective. They have been
            fully reviewed and approved by leading rabbanim and mechanchim.
          </p>
        </div>
        <div class="approach-box">
          <img src="../assets/icon4.svg" class="approach-icon" />
          <p class="txt24">Reintegration</p>
          <p class="txt16">
            Through early detection and preventative intervention, we stop the
            deadly at-risk snowball effect in its tracks. Students catch up to
            their peer group and are ready to succeed.
          </p>
        </div>
      </div>
      <div class="w1170" v-if="inspo.length">
        <div class="center">
          <div class="small-title-line center"></div>
          <h2 class="txt60">Inspirational Content</h2>
          <carousel
            v-if="inspo.length"
            class="carousel shorts"
            :autoplay="true"
            :nav="false"
            :loop="true"
            :dots="false"
            :margin="20"
            :responsive="{ 0: { items: 1 }, 600: { items: 3 } }"
          >
            <img
              height="600"
              @click="popup = video"
              v-for="video in inspo"
              :key="video._id"
              :src="video.cover"
            />
          </carousel>
        </div>
      </div>
      <div class="w1170">
        <div class="center">
          <div class="small-title-line center"></div>
          <h2 class="txt60">Interesting Facts</h2>
          <div class="big-numbers">
            <p>
              LAMDEINI’s
              <br />
              staff
              <span class="big-num">{{ count }}+</span>
              <span class="under-big-num">Tutors</span>
            </p>
            <p>
              Giving Gemara skills to Students in over
              <span class="big-num">{{ count }}+</span>
              <span class="under-big-num">Schools</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <carousel
      class="carousel"
      :autoplay="true"
      :nav="false"
      :loop="true"
      :dots="false"
      :responsive="{ 0: { items: 1 }, 600: { items: 3 } }"
    >
      <img src="../assets/LamdeiniLearning-47.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-4.jpg" alt="" />
      <img src="../assets/lamdeini2.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-129.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-147.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-222.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-70.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-20.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-258.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-319.jpg" alt="" />
      <img src="../assets/donation.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-136.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-177.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-150.jpg" alt="" />
      <img src="../assets/LamdeiniLearning-280.jpg" alt="" />
    </carousel>
    <div class="sec blue-sec">
      <div class="gold-box">
        <div class="gold-box-txt-wrap">
          <div class="small-white-line"></div>
          <p class="txt34">
            When a student asks his rebbe to teach him Torah, if the rebbe does,
            Hashem fills the eyes of both with light
          </p>
          <p class="txt16 mb-30">Talmud Bavli T'mura 16a</p>
          <router-link to="/donate" class="btn-line"
            >Help Our Children</router-link
          >
        </div>
        <img src="../assets/LamdeiniLearning-177.jpg" class="gold-box-img" />
      </div>
      <div class="top-white"></div>
      <div class="under-gold-box">
        <p class="txt40">Is your child struggling with Gemara?</p>
        <router-link to="/#connect" class="btn-line light"
          >We can help.</router-link
        >
      </div>
    </div>
    <carousel
      v-if="testimonial.length"
      class="carousel"
      :autoplay="8000"
      :nav="false"
      :loop="true"
      :dots="true"
      :items="1"
    >
      <!-- <div class="testimonial">
        <div>body</div>
        <p class="testimonial-name">name</p>
      </div>
      <div class="testimonial">
        <div>body</div>
        <p class="testimonial-name">name</p>
      </div> -->

      <div
        v-for="testimony in testimonial"
        :key="testimony._id"
        class="testimonial"
      >
        <p v-html="testimony.body"></p>
        <p class="testimonial-name">{{ testimony.name }}</p>
      </div>
    </carousel>
    <div class="gray-sec">
      <div class="mission-wrap">
        <img src="../assets/LamdeiniLearning-4.jpg" class="mission-img" />
        <div class="mission-txt-wrap">
          <div class="small-blue-line"></div>
          <p class="txt16">
            LAMDEINI’s mission is to provide each child with the tools to learn
            Gemara effectively. LAMDEINI’s professional tutoring program aims to
            transform students struggling to connect to Gemara into confident,
            independent learners based on Rabbi Meir Oratz's proven methodology.
          </p>
          <p class="txt16">
            LAMDEINI’s individualized, subsidized learning plans provide
            effective, affordable solutions for our most precious resource: our
            children.
          </p>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="w1170">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">How I Wish I Could Learn: A Story</h3>
        <div class="story-wrap">
          <div class="story-box">
            <img src="../assets/quotation-marks.svg" class="quotation-marks" />
            <p class="txt16">
              My name is Yitzi and I used to love school. I got great report
              cards and had lots of friends. Learning with Tatty before going to
              bed was the highlight of my day. I knew Tatty thought I was smart
              — I would hear him tell Mommy that I learned like a real yeshivah
              bachur.
            </p>
            <p class="txt16">
              But now I turned stupid. I don’t know the answers in my Gemara
              class because I never know who is saying what. I try and try but I
              can’t seem to remember what the words of the Gemara mean. It’s so
              different to the Chumash.
            </p>
            <p class="txt16">
              My Rebbi used to explain the Gemara lots of times but now he goes
              so fast because he says we’re not beginners anymore. I hate school
              now. I don’t even try to concentrate anymore. What’s the point? My
              friends all know that now I’m dumb. Tatty doesn’t even try to
              learn Gemara with me anymore — he gets frustrated and says that
              I’m not trying. So I hate Tatty and I hate school and I hate my
              friends. I want to run away from school and my house and never
              come back.
            </p>
            <p class="story-last">
              Yitzi doesn’t know yet that all hope is not lost. We can help him.
              We can save him. With your participation we can expand our
              tutoring program and give boys like Yitzi another chance to
              succeed.
            </p>
          </div>
          <img src="../assets/LamdeiniLearning-136.jpg" class="story-img" />
        </div>
      </div>
    </div>
    <div id="approbation" class="approbation-sec">
      <div class="approbation-wrap">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">Approbations</h3>
        <p>
          LAMDEINI has received approval, encouragement and guidance from
          leading Rabbanim (See the below letters):
        </p>
        <carousel
          class="approbation-links carousel"
          :autoplay="4000"
          :margin="10"
          :nav="false"
          :loop="true"
          :dots="true"
          :autoWidth="true"
        >
          <a
            class="approbation-link"
            href="/pdf/HaRav-Yitzchok-Berkowitz.pdf"
            target="_blank"
            >HaRav Yitzchak Berkovits</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Berkowitz-and-Rav-Zilberstein-Hebrew.pdf"
            >HaRav Yitzchak Berkovits and Rav Zilberstein Hebrew</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Elya-Brudny.pdf"
            >HaRav Elya Brudny</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Bender-and-Rabbi-Feiner.pdf"
            >Rabbi Bender and Rabbi Feiner</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/HaRav-Shmuel-Kaminetsky.pdf"
            >HaRav Shmuel Kamenetsky
          </a>
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Rabbi-Shimon-Russel-LCSW.pdf"
            >Rabbi Shimon Russell, LCSW</a
          >
          <a
            class="approbation-link"
            target="_blank"
            href="/pdf/Chofetz-Chayim-School-and-Rav-Zilberstein.pdf"
            >Chofetz Chaim School and Rav Zilberstein</a
          >
        </carousel>
      </div>
      <div class="tri-white-bg"></div>
    </div>
    <div id="connect" class="sec-dark">
      <div class="connect">
        <div class="small-title-line"></div>
        <h3 class="txt60 max600">Contact Us</h3>
        <div class="form-wrap">
          <form
            @submit.prevent="submitForm"
            v-if="formStatus != 'success'"
            class="form"
          >
            <div>
              <input
                placeholder="Your Name (required)"
                type="text"
                name="name"
                class="field"
                required=""
                v-model="form.name"
              />
              <input
                v-model="form.email"
                placeholder="Your Email (required)"
                type="email"
                name="email"
                required=""
                class="field"
              />
              <input
                placeholder="Subject"
                type="text"
                name="name"
                class="field"
                required=""
                v-model="form.subject"
              />
            </div>
            <div>
              <textarea
                v-model="form.message"
                placeholder="Your Message"
                class="field big"
              ></textarea>

              <input type="submit" value="Submit" class="field-btn" />
            </div>
          </form>

          <div class="form-fail" v-if="formStatus == 'err'">
            <p>Oops! Something went wrong while submitting the form.</p>
          </div>
          <div class="form-success" v-if="formStatus === 'success'">
            <p>
              Thank you!
              <br />
              Your submission has been received!
            </p>
          </div>
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import carousel from "sh-v-owl-carousel";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
    carousel,
  },
  data() {
    return {
      popup: null,
      testimonial: [],
      popupactive: false,
      campaignPop: true,
      count: 0,
      startCount: false,
      counterInterval: null,
      inspo: [],
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      formStatus: "",
    };
  },

  mounted() {
    this.getTestimonialList();
    this.getInspo();
    this.counterInterval = setInterval(this.updateCounter, 500);
  },

  methods: {
    updateCounter() {
      if (this.count < 20) {
        this.count++;
      } else {
        clearInterval(this.counterInterval);
      }
    },
    async submitForm() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.$db.collection("connect").post(this.form);
        this.formStatus = "success";
        this.submitting = false;
      } catch (err) {
        this.formStatus = "err";
        this.submitting = false;
      }
    },
    async getInspo() {
      const { data: inspo } = await this.$db
        .collection("inspirationalContent")
        .get();
      this.inspo = inspo.map((i) => ({
        ...i,
        cover: `https://i.ytimg.com/vi/${this.$getYid(i.video)}/oar2.jpg`,
      }));
    },
    async getTestimonialList() {
      const { data: testimonial } = await this.$db
        .collection("testimonial")
        .get();
      this.testimonial = testimonial;
    },
  },
  name: "Home",
};
</script>

<style>
.popup-bg {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  justify-content: center;
  align-items: center;
}

.shorts.carousel {
  margin: 30px 0 60px;
}
.shorts.carousel img {
  height: 600px;
  object-fit: contain;
}
.popup-bg iframe {
  width: calc(90vh / 1.8);
  height: 90vh;
  max-width: calc(800px / 1.8);
  max-height: 800px;
}
</style>
