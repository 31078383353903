<template>
  <div>
    <topnav></topnav>
    <div class="main-sec">
      <div class="center">
        <h1 class="txt60">Team</h1>
        <div class="small-title-line center"></div>
      </div>
      <div class="team">
        <div class="team-gray">
          <div class="team-member">
            <img src="../assets/rav.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Meir Oratz</p>
              <p class="txt18">Founder & Director</p>
            </div>
          </div>
          <p class="txt16">
            Rabbi Meir Oratz is a scion of the renowned Oratz family of educators and has taught in the United States and Eretz Yisroel for 21 years. He learned in Yeshivas Chochmas Shlomo in Yerushalayim, where he was a close talmid of Rav Doniel Wolfson, shlita. He also had the privilege of learning in Yeshivas Darchei Torah in America, forging a strong kesher with both Rav Yaakov Bender, shlita and Rav Shlomo Avigdor Altusky, shlita.
          </p>
          <p class="txt16">
            Rabbi Oratz has designed a unique program based on the legendary methods of Rav Yeshaya Weber, shlita, that assists students in mastering the study of Gemara. Rabbi Oratz has been incredibly successful in helping students, both as a classroom teacher and as a private tutor.
          </p>
          <p class="txt16">
            After witnessing how many students who struggled to connect to Gemara eventually became disillusioned with a Torah way of life, Rabbi Oratz developed a passionate vision of helping struggling students on a larger scale. To this end, Rabbi Oratz has founded Lamdeini, a unique tutoring program that gives students the tools to blossom into confident learners. This program has met with great success. Rabbi Oratz is bilingual, which helps him communicate with both Israeli and American parents. As an
          </p>
          <p class="txt16">
            American Israeli, it is easy for him to understand both cultures and bridge any gaps, thus ensuring that these at-risk students regain satisfaction in their learning and thrive as members of our community.
          </p>
        </div>
        <div class="team-blue">
          <div class="team-small-white-line"></div>
          <h2 class="txt40 light no-marg">Leadership</h2>
          <!-- <div class="team-member on-blue">
            <img
              src="../assets/rav-yitzchok-berkowitz.jpg"
              class="team-member-img"
            />
            <div>
              <p class="txt24">HaRav Yitzchak Berkovits, Shlita</p>
              <p class="txt18">Rabbinical Supervisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/r-shmuel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Shmuel Kamenetsky, Shlita</p>
              <p class="txt18">Rabbinical Advisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/rabbi-bender-1.jpg" class="team-member-img" />
            <div>
              <p class="txt24">HaRav Yaakov Bender, Shlita</p>
              <p class="txt18">Chinuch Advisor</p>
            </div>
          </div> -->
          <!-- <div class="team-member">
            <img src="../assets/rabbi-russel.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Shimon Russell, L.C.S.W., B.C.D</p>
              <p class="txt18">Clinical Advisor</p>
            </div>
          </div> -->
          <div class="team-member  on-blue">
            <img src="../assets/Abramov.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Yisroel Abramov</p>
              <p class="txt18">Director of Training Program & Professional Development</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Rotenberg.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Yirmiyahu Rotenberg</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Nadler.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Dovid Nadler</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Farber.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Dovid Farber</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Novice.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Chaim Novice</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Drutman.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Tzvi Drutman</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Rabbi-Yisrael-Asulin.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Yisrael Asulin</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Rabbi-Pinchas-Shtiglitz.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Pinchas Shtiglitz</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/Rabbi-Shmuel-Chassid.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Rabbi Shmuel Chassid</p>
              <p class="txt18">LAMDEINI Tutor</p>
            </div>
          </div>
          <div class="team-member">
            <img src="../assets/logo-small.jpg" class="team-member-img" />
            <div>
              <p class="txt24">Mrs. Basya Lifshutz</p>
              <p class="txt18">Office Manager</p>
            </div>
          </div>
        </div>
      </div>
      <video
      controls
        class="bg-vid"
        poster="../assets/bg-pic.jpg"
        id="bgvid"
      >
        <source
          src="https://sfo2.digitaloceanspaces.com/bagel/Lamdeini/LamdeiniFullVideo02.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Team",
};
</script>
